$(function() {

    satisfaction();

    $(".rslides").responsiveSlides({
        auto: false,
        pager: true,
        nav: true,
        speed: 500,
        width: '100%',
        namespace: "centered-btns"
    });


    $('.navbar_cart').click(function(){
        $('.content_panier').show();
    }
    );

    $('#cross').click(function(){
            $('.content_panier').hide();
        }
    );

    $("ul.demo2").ytplaylist({addThumbs:true, autoPlay: false, holderId: 'ytvideo2'});

/*
    $(".slides").vegas({
        slides: [

            { src: "/img/slider/visuel-4-HD.jpg" },
            { src: "/img/slider/TEETAN_slide3.png" },
            { src: "/img/slider/TEETAN_Moto1.png" },
            { src: "/img/slider/visuel-2-1-HD.jpg" }

        ],
        preloadVideo:true,
        overlay:'/img/overlays/01.png',
        color:'#000000',
        walk: function (index, slideSettings) {
            console.log("Slide index " + index + " image " + slideSettings.src);
            $('.slide').hide();
            $('#slide'+index).show();
        },
        delay:5000,
        autoplay:true
    });
*/

/*
    $(".slides").vegas({
        slides: [

            {   src: '/img/slider/TEETAN_Moto1.png',
                video: {
                    src: [
                        '/videos/video1.mp4',
                    ],
                    loop: false,
                    autoplay:true,
                    mute: false,
                    preloadVideo:true
                }
            },
            {   src: '/img/slider/TEETAN_Moto1.png',
                video: {
                    src: [
                        '/videos/film2.mp4',
                    ],
                    loop: false,
                    autoplay:true,
                    mute: false,
                    preloadVideo:true
                }
            },

        ],
        preloadVideo:true,
        overlay:'/img/overlays/01.png',
        color:'#000000',
        walk: function (index, slideSettings) {
            console.log("Slide index " + index + " image " + slideSettings.src);
            $('.slide').hide();
            $('#slide'+index).show();
        },
        delay:10000,
        autoplay:false
    });
*/


    $(".slides2").vegas({
        slides: [

            {   src: '/img/slider/TEETAN_Moto1.png',
                video: {
                    src: [
                        '/videos/video1.mp4',
                    ],
                    loop: false,
                    autoplay:true,
                    mute: false,
                    preloadVideo:true
                }
            },
            {   src: '/img/slider/visuel-2-1-HD.jpg',
                video: {
                    src: [
                        '/videos/film_laponie.mov',
                    ],
                    loop: false,
                    autoplay:true,
                    mute: false,
                    preloadVideo:true
                }
            },
            { src: "/img/slider/TEETAN_slide3.png" },


        ],
        preloadVideo:true,
        shuffle:true,
        overlay:'/img/overlays/01.png',
        walk: function (index, slideSettings) {
            console.log("Slide index " + index + " image " + slideSettings.src);
            $('.slide').hide();
           
          

            if (slideSettings.src == '/img/slider/TEETAN_slide3.png'){
                $('.TEETAN_slide3').show();
            }
            else if (slideSettings.src == '/img/slider/TEETAN_Moto1.png'){
                $('.TEETAN_Moto1').show();
            }
            else if (slideSettings.src == '/img/slider/visuel-2-1-HD.jpg'){
                $('.visuel-2-1-HD').show();
            }

        },
        autoplay:false

    });

    $('a#previous').on('click', function () {
        $(".slides2").vegas('options', 'transition', 'slideRight2').vegas('previous');
        return false;
    });

    $('a#next').on('click', function () {
        $(".slides2").vegas('options', 'transition', 'slideLeft2').vegas('next');
        return false;
    });
/*
    $( '.slides' ).hover(
        function() {
            $( '.slides' ).vegas('pause');
        }, function() {

            if ($('.slide:hover').length) {
                $('.slide').hover(function () {
                    $('.slides').vegas('pause');
                }, function () {
                    $('.slides').vegas('play');
                });
            }
            else{
                $('.slides').vegas('play');
            }
        }
    );
    */


    $('.check').click(function(){
        $('.multi_facturation').slideToggle();
    });

    /*
    $( "#pays1" ).autocomplete({
        source: "/searchPays/",
        minLength: 3,
        select: function( event, ui ) {
            log( ui.item ?
            "Selected: " + ui.item.value + " aka " + ui.item.id :
            "Nothing selected, input was " + this.value );
        }
    });
    */


    init_code_promo();



    $('.img_slider_icon').hover(function(){
        var src = $(this).attr('src');
        src = src.split('.');

        src = src[0]+'_survol.png';
        $(this).attr('src', src);


    }, function(){
        var src = $(this).attr('src');
        src = src.replace('_survol', '');

        $(this).attr('src', src);
    });

    var note = $('#note'),
        ts = new Date(2016, 02, 03, 12, 0, 0);


    $('#countdown').countdown({
        timestamp	: ts,
        callback	: function(days, hours, minutes, seconds){

            var message = "";

            message += days + " Jours" + ( days==1 ? '':'s' ) + ", ";
            message += hours + " Heures" + ( hours==1 ? '':'s' ) + ", ";
            message += minutes + " Minutes" + ( minutes==1 ? '':'s' ) + " and ";
            message += seconds + " Secondes" + ( seconds==1 ? '':'s' ) + " <br />";


            message += "Avant ouverture des portes";

            note.html(message);
        }
    });


    /*
    $('.navbar_cart').hover(function(){
        $('.content_panier').show();
    }, function() {
       if(!$('.content_panier').is(":hover")){
           $('.content_panier').hide();
       }

    });

    $('.content_panier').hover(function(){
        $('.content_panier').show();
    }, function() {
        if(!$('.navbar_cart').is(":hover")){
            $('.content_panier').hide();
        }

    });
    */


  //  $(".imgLiquid").imgLiquid();
    $.ajaxPrefilter(function(options, originalOptions, xhr) {
        var token = $('meta[name="csrf_token"]').attr('content');

        if (token) {
            return xhr.setRequestHeader('X-XSRF-TOKEN', token);
        }
    });

    init_action_cart();


    //L'adresse est remplie => on active le bloc
    if ($('#adress_id').val() != ''){
        $('.form_paiement').addClass('border');
        $('.form_adress').removeClass('border');



        $('.cache_form_paiement').css('display', 'none');
    }
    else {
        $('.form_paiement').css('display', 'none');
    }


    $(".down2").click(function() {
        scrollToAnchor('home');
    });

    $('.add_to_cart').click(function(){
        ga('ecommerce:addItem', {
            'id': $('#id_product').val(),       // Transaction ID. Required.
            'name': $('#name_product').val(),   // Product name. Required.
            'sku': $('#sku_product').val(),     // SKU/code.
            'category': 'Camera',               // Category or variation.
            'price': $('#price_product').val(), // Unit price.
            'quantity': $('#qty').val()         // Quantity.
        });
    });

});


function scrollToAnchor(aid){
    var aTag = $("#"+ aid +"");
    $('html,body').animate({scrollTop: aTag.offset().top},'slow');

    return false;
}


function satisfaction(){
    var comment1 = 'Matthieu : " Un grain d’image exceptionnel " ';
    var star1 = ' <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>';

    var comment2 = 'Marion: " Un pack quasiment complet qui répond à mes attentes "';
    var star2 = ' <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star-o"></i>';

    var comment3 = 'Philippe: " 2x fois moins cher que la concurrence haut de gamme! Je recommande "';
    var star3 = ' <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>';

    var comment4 = 'Alexandre: " Une caméra au top de la qualité avec tous les accessoires indispensable!! "';
    var star4 = ' <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star-o"></i>';

    var comment5 = 'Frédéric: " Mes coureurs vélo ne peuvent plus s’en passer, merci Teetan " ';
    var star5 = ' <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>';

    var comment6 = 'Patrick vidéaste pro: " Aucune comparaison possible, Teetan offre un rapport qualité / prix au top "';
    var star6 = ' <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>        <i class="fa fa-star"></i>';



    var i =  Math.floor((Math.random() * 6) + 1);
    setTimeout(function(){
        switch (i){
            case 1:
                $('.star').html(star1);
                $('.comment_star').html(comment1);

                break;

            case 2:
                $('.star').html(star2);
                $('.comment_star').html(comment2);

                break;

            case 3:
                $('.star').html(star3);
                $('.comment_star').html(comment3);

                break;

            case 4:
                $('.star').html(star4);
                $('.comment_star').html(comment4);

                break;

            case 5:
                $('.star').html(star5);
                $('.comment_star').html(comment5);

                break;

            case 6:
                $('.star').html(star6);
                $('.comment_star').html(comment6);

                break;
        }

        ++i;

        if (i == 7){
            i = 1;
        }
    }, 1000);

}



function init_code_promo()
{
    $('.submit_code_promo').click(function(){
        var code_promo = $('.code_promo').val();
        $('#chargement').html('Application du coupon');
        $('#chargement').fadeIn();
        $.ajax({
            url: '/addCoupon',
            type: 'POST',
            data: { code_promo: code_promo },
            success: function (data) {

                $('.panier').html(data);
                init_action_cart();

                $('#chargement').html('Coupon ajouté');

                setTimeout(function(){
                    $('#chargement').fadeOut('slow');
                    $('#chargement').html('');
                }, 2000);
            },
        });
    });

}

function init_action_cart(){

    $('.delete_item').click(function(){
        var id_product  = $(this).attr('data-product-id');

        $('#chargement').html('Suppression en cours');
        $('#chargement').fadeIn();

        $.ajax({
            url: '/removeItemCart',
            type: 'POST',
            data: { id_product: id_product },
            success: function (data) {
                if(data != 'erreur')
                {
                    $('.panier').html(data);
                    init_action_cart();

                    $('#chargement').html('Produit supprimé');

                    setTimeout(function(){
                        $('#chargement').fadeOut('slow');
                        $('#chargement').html('');
                    }, 2000);

                }
            },
        });


    });


    $('.update_item').click(function(){
        var qte         = $(this).parent().find('.input_quantite').val();
        var id_product  = $(this).attr('data-product-id');


        $('#chargement').html('Mise à jour en cours...');
        $('#chargement').fadeIn();
        $.ajax({
            url: '/majCart',
            type: 'POST',
            data: {qte: qte, id_product: id_product },
            success: function (data) {
                if(data != 'erreur')
                {
                    $('.panier').html(data);
                    init_action_cart();

                    $('#chargement').html('Votre panier a été mis à jour');

                    setTimeout(function(){
                        $('#chargement').fadeOut('slow');
                        $('#chargement').html('');
                    }, 2000);

                }
            },
        });

    });
}